
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import Tinymce from '@/components/tinymce/Index.vue';

import { useUpdateInformation, useInformation } from '@/composables/api';
import { PartialInformation } from '@/interfaces/Information';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialInformation = {
  type: '',
  title: '',
  content: ''
};

const rules = {};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Tinymce
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const informationId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref(DEFAULT_FORM_VALUES);
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateInformation();
    const { t } = useI18n();

    const handleInputChange = (value: string) => {
      formValues.value.content = value;
    };

    const { data, dataUpdatedAt } = useInformation({ informationId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const updateContent = (content: string) => {
      formValues.value.content = content;
    };
    const submitForm = () => {
      const form = unref(formRef);
      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { informationId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.replace({
                      name: 'list-informations'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                  }
                }
              );
            }
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      handleInputChange,
      isUpdatedLoading,
      submitForm,
      t,
      updateContent
    };
  }
});
